import { forwardRef, useCallback } from "react";
import clsx from "clsx";
import Image from "next/image";
import { useRouter } from "next/router";
import { useGetPostsByProjectId } from "@/features/post/api/getPostsByProjectId";
import { useCurrentPost } from "@/features/project/providers/CurrentPostProvider";
import { useContainerRefContext } from "@/features/project/providers/ContainerRefProvider";
import { useHeaderHeight } from "@/hooks/useHeaderHeight";

interface Props {
  projectId: number;
  detailHeaderHeight: number;
}

const ThumbnailsOnProjectDetail = forwardRef<HTMLDivElement, Props>(
  function ThumbnailsOnProjectDetail({ projectId, detailHeaderHeight }, ref) {
    const { containerRef } = useContainerRefContext();
    const { currentPostIndex } = useCurrentPost();
    const router = useRouter();
    const headerHeight = useHeaderHeight();

    const { data: posts } = useGetPostsByProjectId({
      id: projectId,
      limit: 100,
      token: router.query.token as string,
    });

    const handleScroll = useCallback(
      (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        targetId: string,
      ) => {
        e.preventDefault();
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
          const scroller = containerRef ? containerRef.current : window;
          const scrollY = containerRef
            ? containerRef.current.scrollTop
            : window.scrollY;

          // ドキュメントの上端からの絶対位置
          const absolutlyTop =
            targetElement.getBoundingClientRect().top + scrollY;

          const adjust = containerRef
            ? containerRef.current.offsetTop + detailHeaderHeight
            : headerHeight + detailHeaderHeight;

          const top = absolutlyTop - adjust;

          scroller.scrollTo({
            top,
            behavior: "smooth",
          });
        }
      },
      [containerRef, headerHeight, detailHeaderHeight],
    );

    // プレスホルダーの描画
    if (!posts || posts.length === 0) {
      return (
        <div className="relative">
          <div className="grid grid-cols-2 gap-16">
            {/* プロジェクトにアップロードされている数だけプレスホルダーを描画する */}
            {Array.from({ length: 8 }).map((_, index) => (
              <div key={index} className="aspect-square bg-main w-full" />
            ))}
          </div>
        </div>
      );
    }

    return (
      <div ref={ref} className={clsx("relative grid grid-cols-2 gap-16 pb-80")}>
        {posts &&
          posts.map((post, index) => (
            <a
              key={post.id}
              href={clsx(`#${index + 1}`)}
              className={clsx(
                "relative aspect-square",
                // for active
                currentPostIndex === index && [
                  "after:content-[''] after:block after:absolute after:inset-0 after:border-2 after:border-black",
                  "after:transition-opacity",
                ],

                // for hover
                "hover:brightness-90 transition-all",
              )}
              onClick={(e) => handleScroll(e, `${index + 1}`)}
            >
              <Image
                className="object-cover object-center"
                src={post.upload_image.urls.small}
                alt={`${index + 1}枚目の事例写真`}
                fill
                unoptimized
              />
            </a>
          ))}
      </div>
    );
  },
);
export default ThumbnailsOnProjectDetail;
