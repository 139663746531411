import { ProjectDetailResponse } from "@/web-client";
import { FC } from "react";
import clsx from "clsx";
import formatNumber from "@/utils/formatNumber";
import LikeProjectIconButton from "@/features/project/components/LikeProjectIconButton";

interface LikeBtnProps {
  project: ProjectDetailResponse;
}

const LikeProjectIconButtonWithBadge: FC<LikeBtnProps> = ({ project }) => {
  return (
    <div className="relative">
      {project.likes_count > 0 && (
        <div
          className={clsx(
            "absolute right-0 top-0",
            "translate-x-1/2",
            "bg-white rounded-full text-center text-primary",
            "whitespace-nowrap",
            "text-tiny font-bold leading-none",
            "py-2 px-4",
            "shadow-xs",
          )}
        >
          {formatNumber(project.likes_count)}
        </div>
      )}
      <LikeProjectIconButton project={project} />
    </div>
  );
};
export default LikeProjectIconButtonWithBadge;
