import { CreditItem } from "@/components/CreditItem";
import formatConstructionType from "@/utils/formatConstructionType";
import { ProjectCreditValue, ProjectDetailResponse } from "@/web-client";
import clsx from "clsx";
import Link from "next/link";
import { FC, PropsWithChildren, useMemo } from "react";

interface Props {
  project: ProjectDetailResponse;
}

const SectionDatasOnProjectDetail: FC<Props> = ({ project }) => {
  const displayCredit = useMemo<ProjectCreditValue[]>(() => {
    return project.credit
      ? project.credit.filter((c) => c.values.length > 0)
      : [];
  }, [project]);

  return (
    <div className={clsx("grid gap-24", "laptop:gap-24 laptop:grid-cols-2")}>
      <div>
        <DataBox label={"DATA"}>
          <ul className="divide-y [&>*]:border-primary">
            <li>
              <CreditItem
                label={"ビルディングタイプ"}
                content={
                  <Link
                    href={`/architectures/projects/search?building_type_id=${project.building_type.id}`}
                    className="underline hover:no-underline"
                  >
                    {project.building_type.name}
                  </Link>
                }
              />
            </li>

            {project.structure_types.length > 0 && (
              <li>
                <CreditItem
                  label={"構造"}
                  content={project.structure_types.map(
                    (structure_type, index) => {
                      return (
                        <Link
                          key={index}
                          href={`/architectures/projects/search?structure_type_id=${structure_type.id}`}
                          className="underline hover:no-underline block text-right"
                        >
                          {structure_type.label}
                        </Link>
                      );
                    },
                  )}
                />
              </li>
            )}

            {project.construction_type && (
              <li>
                <CreditItem
                  label={"工事種別"}
                  content={
                    <Link
                      href={`/architectures/projects/search?construction_type=${project.construction_type}`}
                      className="underline hover:no-underline"
                    >
                      {formatConstructionType(project.construction_type)}
                    </Link>
                  }
                />
              </li>
            )}

            {project.floor_space && (
              <li>
                <CreditItem
                  label={"延べ床面積"}
                  content={`${project.floor_space}㎡`}
                />
              </li>
            )}

            {project.completion && (
              <li>
                <CreditItem label={"竣工"} content={`${project.completion}`} />
              </li>
            )}
          </ul>
        </DataBox>
      </div>
      <div>
        {displayCredit.length > 0 && (
          <DataBox label={"CREDIT"}>
            <ul className="divide-y [&>*]:border-primary">
              {displayCredit.map((item, i) => {
                return (
                  <li key={i}>
                    <CreditItem
                      label={item.label}
                      content={item.values.join(" / ")}
                    />
                  </li>
                );
              })}
            </ul>
          </DataBox>
        )}
      </div>
    </div>
  );
};
export default SectionDatasOnProjectDetail;

type DataBoxProps = {
  label: string;
};

const DataBox: FC<PropsWithChildren<DataBoxProps>> = ({
  label,
  children,
}): JSX.Element => {
  return (
    <div className="grid gap-4">
      <p className="text-tiny font-bold">{label}</p>
      <div>{children}</div>
    </div>
  );
};
