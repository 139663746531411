import IconButton from "@/components/IconButton";
import useLike from "@/features/like/hooks/useLike";
import { useGetProject } from "@/features/project/api/getProject";
import { LikeKind, ProjectDetailResponse } from "@/web-client";
import IconLike from "@/assets/imgs/svg/icon-like.svg";
import IconLikeFill from "@/assets/imgs/svg/icon-like-fill.svg";
import { FC } from "react";
import { IconButtonVariants } from "@/components/IconButton/types/IconButtonVariants";
import clsx from "clsx";
import { getReasonText } from "@/utils/getReasonText";
import { RedirectReason } from "@/domain/values/RedirectReason";
import { useSignInRequiredAction } from "@/features/user_authentication";

interface LikeBtnProps {
  project: ProjectDetailResponse;
  variant?: IconButtonVariants;
}

const LikeProjectIconButton: FC<LikeBtnProps> = ({
  project: initProject,
  variant = "primary",
}) => {
  const { data: project, mutate } = useGetProject(initProject.id, initProject);

  const { likeHandler } = useLike<ProjectDetailResponse>(
    project,
    LikeKind.PROJECT,
    (updatedProject) => mutate(updatedProject, false),
  );

  const { executeAction, SignInModalComponent } = useSignInRequiredAction({
    action: likeHandler,
    message: getReasonText(RedirectReason.Like),
  });

  return (
    <>
      <IconButton
        className={clsx(variant === "secondary" && "border border-primary")}
        onClick={executeAction}
        variant={variant}
        size="large"
        rounded
      >
        {project.has_liked ? (
          <span className="fill-like">
            <IconLikeFill />
          </span>
        ) : (
          <IconLike />
        )}
      </IconButton>

      {SignInModalComponent}
    </>
  );
};
export default LikeProjectIconButton;
