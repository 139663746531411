import { FC, useMemo } from "react";
import { useRouter } from "next/router";
import Button from "@/components/Button";
import useDisclosure from "@/hooks/useDisclosure";
import ProductVariationItem from "@/features/product_variation/components/ProductVariationItem";
import IconArrowDown from "@/assets/imgs/svg/icon-arrow-down.svg";
import { useGetPostsByProjectId } from "@/features/post/api/getPostsByProjectId";
import { useExtractProductVariationsFromPosts } from "@/features/product_variation/hooks/useExtractProductVariationFromPosts";
import { padArrayToMultipleOfN } from "@/utils/padArrayToMultipleOfN";
import useDevice from "@/hooks/useDevice";
import { ProductVariationWithProductResponse } from "@/web-client";
import ModalEntityListWrapper from "@/components/ModalEntityListWrapper";

interface SectionProductVariationsProps {
  projectId: number;
}

// もっと見るを押す前の最大表示数
const NUM_OF_MAX_DISPLAY_PRODUCT_VARIATIONS = 8;

const SectionProductVariationsOnProjectDetail: FC<
  SectionProductVariationsProps
> = ({ projectId }): JSX.Element => {
  const { isPc } = useDevice();
  const router = useRouter();
  const { isOpen, openHandler } = useDisclosure(false);

  const { data: posts, mutate } = useGetPostsByProjectId({
    id: projectId,
    limit: 100,
    token: router.query.token as string,
  });

  // ポストから抽出したプロダクトバリエーション
  const productVariations = useExtractProductVariationsFromPosts(posts);

  // 最大数で丸め込んだプロダクトバリエーション
  const roundedProductVariations = useMemo(() => {
    if (isOpen) return productVariations;

    // 最大の数以下にまるめこむ
    const rounded = productVariations.slice(
      0,
      NUM_OF_MAX_DISPLAY_PRODUCT_VARIATIONS,
    );

    return rounded;
  }, [isOpen, productVariations]);

  // PCは配列が4の倍数、SPは配列が2の倍数になるようにする
  const displayProductVariations = useMemo<
    (ProductVariationWithProductResponse | undefined)[]
  >(() => {
    return padArrayToMultipleOfN(roundedProductVariations, isPc ? 4 : 2);
  }, [roundedProductVariations, isPc]);

  // ボタンの表示条件
  const displayButton = useMemo(
    () => productVariations.length > NUM_OF_MAX_DISPLAY_PRODUCT_VARIATIONS,
    [productVariations],
  );

  // セクションを表示しないと、アンカーが正常に動作しなくなるため、空でもidをつけたsectionを返す
  if (productVariations.length === 0) return null;

  return (
    <div className="grid gap-y-24">
      <div className="grid gap-y-16">
        <h2 className="font-bold text-base">使用建材・家具</h2>
        <ModalEntityListWrapper responses={roundedProductVariations}>
          <div className="grid grid-cols-2 laptop:grid-cols-4 gap-24">
            {displayProductVariations.map((productVariation, index) =>
              productVariation ? (
                <ProductVariationItem
                  key={productVariation.id}
                  productVariation={productVariation}
                  mutate={() => mutate()}
                />
              ) : (
                // プレスホルダーを表示する
                <div key={index} className="bg-main aspect-square" />
              ),
            )}
          </div>
        </ModalEntityListWrapper>
      </div>

      {!isOpen && displayButton && (
        <Button
          color="ghost"
          onClick={openHandler}
          leftIcon={<IconArrowDown width={16} height={16} />}
        >
          もっと見る
        </Button>
      )}
    </div>
  );
};
export default SectionProductVariationsOnProjectDetail;
