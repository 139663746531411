import useFetch, { FetchResponse } from "@/hooks/useFetch";
import client from "@/utils/api/client";
import {
  DefaultApiProjectsIdGetRequest,
  ProjectDetailResponse,
} from "@/web-client";
import { AxiosResponse } from "axios";

export const getProject = (
  request: DefaultApiProjectsIdGetRequest,
): Promise<AxiosResponse<ProjectDetailResponse>> =>
  client.projectsIdGet(request);

export const useGetProject = (
  id?: number,
  initialProject?: ProjectDetailResponse,
): FetchResponse<ProjectDetailResponse> => {
  const response = useFetch<ProjectDetailResponse>(
    id ? `/projects/${id}` : null,
    async () => {
      const { data } = await getProject({ id });

      return data;
    },
    initialProject,
    { waitForAuth: true },
  );

  return response;
};
