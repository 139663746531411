import { FC, useCallback, useMemo } from "react";
import clsx from "clsx";
import Button from "@/components/Button";
import { ImageSize } from "@/consts/ImageSize";
import { useGetPostsByProjectId } from "@/features/post/api/getPostsByProjectId";
import PostItem from "@/features/post/components/PostItem";
import useArrayMutation from "@/hooks/useArrayMutation";
import useClampItems from "@/hooks/useClampItems";
import useDevice from "@/hooks/useDevice";
import { PostResponse, ProjectDetailResponse } from "@/web-client";
import { useRouter } from "next/router";
import { useCurrentPost } from "@/features/project/providers/CurrentPostProvider";
import CenterPositionDetector from "@/components/IntersectionDetector";
import IconImages from "@/assets/imgs/svg/icon_images-outlined.svg";
import ModalEntityListWrapper from "@/components/ModalEntityListWrapper";

interface Props {
  project: ProjectDetailResponse;
}

const MinDisplayPhotoNum = 4;

const SectionPostsOnProjectDetail: FC<Props> = ({ project }) => {
  const { isSp } = useDevice();
  const router = useRouter();
  const { update } = useArrayMutation<PostResponse>();
  const { setCurrentPostIndex } = useCurrentPost();

  const { data: posts, mutate } = useGetPostsByProjectId({
    id: project.id,
    limit: 100,
    token: router.query.token as string,
  });

  const {
    items: clamppedPosts,
    open,
    isEnable,
  } = useClampItems<PostResponse>(
    posts || [],
    MinDisplayPhotoNum,
    isSp && (posts || []).length > MinDisplayPhotoNum,
  );

  // 中央に来たときの処理
  const centerDetectorChangeHandler = useCallback<
    (value: boolean, index: number) => void
  >(
    (isIntersecting, index) => {
      if (isIntersecting) {
        setCurrentPostIndex(index);
      }
    },
    [setCurrentPostIndex],
  );

  // プレスホルダーの描画
  if (!posts || posts.length === 0) {
    return (
      <div className="relative">
        <div className="grid gap-y-24">
          {/* プロジェクトにアップロードされている数だけプレスホルダーを描画する */}
          {Array.from({ length: project.posts_count }).map((_, index) => (
            <div key={index} className="aspect-video bg-main w-full" />
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="relative">
      {posts && posts.length > 0 && (
        <ModalEntityListWrapper responses={posts}>
          <div className="grid gap-y-4 laptop:gap-y-24">
            {clamppedPosts.map((post, index) => (
              <CenterPositionDetector
                key={index}
                onChange={(isIntersecting) =>
                  centerDetectorChangeHandler(isIntersecting, index)
                }
              >
                <div id={String(index + 1)}>
                  <LimitedPostItem
                    post={post}
                    isPublished={project.is_published}
                    mutate={(updated) => mutate(update(posts, updated))}
                  />
                </div>
              </CenterPositionDetector>
            ))}
          </div>
        </ModalEntityListWrapper>
      )}

      {isEnable && (
        <div
          className={clsx(
            "absolute inset-x-0 height-[56px] py-8 px-16",
            // ピッタリにすると謎の線が現れたため、1px下にずらす
            "bottom-[-1px]",
            // ボタンを幅100%にするため
            "grid",
            // グラデーション
            "bg-gradient-to-b from-transparent to-white",
          )}
        >
          <Button
            color="secondary"
            onClick={open}
            leftIcon={<IconImages width={16} height={16} />}
          >
            すべての写真を表示
          </Button>
        </div>
      )}
    </div>
  );
};
export default SectionPostsOnProjectDetail;

// 高さが限定されたポストアイテム
interface LimitedPostItemProps {
  post: PostResponse;
  mutate: (updated: PostResponse) => void;
  isPublished: boolean;
}

const LimitedPostItem: FC<LimitedPostItemProps> = ({
  post,
  mutate,
  isPublished,
}) => {
  const isPortrait = useMemo(
    () => post.upload_image.height > post.upload_image.width,
    [post],
  );

  return (
    <div
      className={clsx(
        "relative bg-main",
        isPortrait && "flex justify-center items-center",
      )}
    >
      <PostItem
        post={post}
        imageSize={ImageSize.Large}
        mutate={(updated) => mutate(updated)}
        showButtons={isPublished}
        imgStyle={{
          // 縦画像のみ最大高さを指定
          maxHeight: isPortrait ? "120vh" : "unset",
        }}
      />
    </div>
  );
};
