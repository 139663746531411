import React, {
  createContext,
  useContext,
  RefObject,
  FC,
  PropsWithChildren,
} from "react";

interface ContainerRefContextProps {
  containerRef: RefObject<HTMLDivElement>;
}

const ContainerRefContext = createContext<ContainerRefContextProps | undefined>(
  undefined,
);

export const useContainerRefContext = (): ContainerRefContextProps => {
  const context = useContext(ContainerRefContext);

  if (!context) {
    throw new Error(
      "useContainerRefContext must be used within a ContainerRefProvider",
    );
  }

  return context;
};

interface ContainerRefProviderProps {
  containerRef: RefObject<HTMLDivElement>;
}

export const ContainerRefProvider: FC<
  PropsWithChildren<ContainerRefProviderProps>
> = ({ children, containerRef }) => {
  return (
    <ContainerRefContext.Provider value={{ containerRef }}>
      {children}
    </ContainerRefContext.Provider>
  );
};
