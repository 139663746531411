import IconButton from "@/components/IconButton";
import useDisclosure from "@/hooks/useDisclosure";
import { ProjectDetailResponse } from "@/web-client";
import IconShare from "@/assets/imgs/svg/icon_share-outlined.svg";
import { FC } from "react";
import dynamic from "next/dynamic";
import { PUBLIC_URL } from "@/config/env";
import { IconButtonVariants } from "@/components/IconButton/types/IconButtonVariants";
import clsx from "clsx";
const DynamicDialogShare = dynamic(() => import("@/components/DialogShare"));

interface ShareProjectIconButtonProps {
  project: ProjectDetailResponse;
  variant?: IconButtonVariants;
}

const ShareProjectIconButton: FC<ShareProjectIconButtonProps> = ({
  project,
  variant = "primary",
}) => {
  const { isOpen, closeHandler: close, openHandler: open } = useDisclosure();

  return (
    <>
      <IconButton
        className={clsx(variant === "secondary" && "border border-primary")}
        onClick={open}
        size="large"
        variant={variant}
        rounded
      >
        <IconShare />
      </IconButton>

      {isOpen && (
        <DynamicDialogShare
          isOpen={isOpen}
          close={close}
          title="プロジェクトをシェア"
          message="このプロジェクトをSNSで共有、またはURLをコピーすることができます。"
          img={project.main_image.urls.small}
          url={`${PUBLIC_URL}/projects/${project.id}`}
        />
      )}
    </>
  );
};
export default ShareProjectIconButton;
