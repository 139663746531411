import clsx from "clsx";
import Button from "@/components/Button";
import useBtnRequiresIsDesigner from "@/features/user/hooks/useBtnRequiresDesigner";
import useAnalytics from "@/hooks/useAnalytics";
import useDisclosure from "@/hooks/useDisclosure";
import client from "@/utils/api/client";
import { FC, useCallback } from "react";
import { useDispatch } from "react-redux";
import { showSnackbar } from "@/stores/snackbar";
import { useGetProject } from "@/features/project/api/getProject";
import RenderIfAuth from "@/components/RenderIfAuth";
import dynamic from "next/dynamic";

const DialogPrivateShare = dynamic(() =>
  import("@/features/project/components/DialogPrivateShare").then(
    (mod) => mod.DialogPrivateShare,
  ),
);

interface Props {
  projectId: number;
}

const EditProjectDetailBar: FC<Props> = ({ projectId }) => {
  const { data: project, mutate } = useGetProject(projectId);

  const { event } = useAnalytics();
  const dispatch = useDispatch();

  const publish = useCallback(async () => {
    if (project.posts_count < 1) {
      alert(
        "プロジェクトを公開するには、1枚以上の写真を含める必要があります。",
      );

      return;
    }

    await client.projectsIdPublishPost({ id: project.id });
    project.is_published = true;
    mutate && mutate(project);

    event("publish_project", {
      item_id: `PJ_${project.id}`,
    });

    dispatch(
      showSnackbar({
        severity: "success",
        message: "プロジェクトを公開しました",
      }),
    );
  }, [project, mutate, dispatch, event]);

  const privateShareDisclosure = useDisclosure();

  const { clickHandler: clickPrivateShareBtnHandler } =
    useBtnRequiresIsDesigner(privateShareDisclosure.openHandler);

  const { clickHandler: clickPublishBtnHandler } =
    useBtnRequiresIsDesigner(publish);

  return (
    <>
      <div className={clsx("relative bg-main p-16 rounded-xs", "laptop:pl-24")}>
        <div
          className={clsx(
            "grid gap-8",
            "laptop:grid-flow-col laptop:grid-cols-[1fr_auto] laptop:gap-24",
            "max-w-[1600px] mx-auto",
          )}
        >
          <div
            className={clsx(
              "grid items-center",
              "laptop:grid-flow-col laptop:grid-cols-[auto_1fr] laptop:gap-16",
            )}
          >
            <p className="font-bold text-sm">未公開のプロジェクト</p>
            <p className="text-sm">
              このプロジェクトはまだ公開されていません。
            </p>
          </div>

          <div className="flex gap-8 items-center">
            <Button
              color="outlined"
              size="small"
              className="small:w-full"
              onClick={clickPrivateShareBtnHandler}
            >
              非公開で共有
            </Button>

            <Button
              onClick={clickPublishBtnHandler}
              size="small"
              className="small:w-full"
            >
              公開する
            </Button>
          </div>
        </div>
      </div>

      <RenderIfAuth>
        {privateShareDisclosure.isOpen && (
          <DialogPrivateShare
            isOpen={privateShareDisclosure.isOpen}
            close={privateShareDisclosure.closeHandler}
            projectId={project.id}
          />
        )}
      </RenderIfAuth>
    </>
  );
};
export default EditProjectDetailBar;
