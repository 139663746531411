import Avatar, { AvatarSize } from "@/components/Avatar";
import { ProjectDetailResponse, UserResponse } from "@/web-client";
import Link from "next/link";
import { FC, ReactNode, useMemo } from "react";
import IconBook from "@/assets/imgs/svg/icon_menu_book-outlined.svg";
import useDevice from "@/hooks/useDevice";

interface Props {
  project: ProjectDetailResponse;
}

const LeftInfoOnProjectDetail: FC<Props> = ({ project }) => {
  const { isPc } = useDevice();
  const mainDatas = useMemo<{ label: string; value: ReactNode }[]>(() => {
    const datas = [];

    // 施工を取得する
    const constructor = project.credit?.find(({ label }) => label === "施工");

    if (constructor && constructor.values.length > 0)
      datas.push({
        label: constructor.label,
        value: constructor.values.join(", "),
      });

    // 構造を取得する
    if (project.structure_types && project.structure_types.length > 0) {
      datas.push({
        label: "構造",
        value: (
          <ul className="flex flex-col items-end">
            {project.structure_types.map(({ id, label }) => (
              <li key={id}>
                <Link
                  className="underline hover:no-underline"
                  href={`/architectures/projects/search?structure_type_id=${id}`}
                >
                  {label}
                </Link>
              </li>
            ))}
          </ul>
        ),
      });
    }

    // 延べ床
    if (project.floor_space)
      datas.push({
        label: "延床面積",
        value: `${project.floor_space}㎡`,
      });

    // 竣工
    if (project.completion)
      datas.push({
        label: "竣工",
        value: project.completion,
      });

    return datas;
  }, [project]);

  return (
    <div className="grid gap-24 pt-16">
      <div className="grid gap-8 justify-items-center">
        <Link href={`/teams/${project.team.id}/projects`}>
          <Avatar
            size={AvatarSize.Large}
            src={project.team.logo?.urls.small || "/imgs/default_team_icon.png"}
            alt={project.team.name}
          />
        </Link>
        <p className="text-xs">
          <Link href={`/teams/${project.team.id}/projects`}>
            {project.team.name}
          </Link>
        </p>
      </div>

      <div className="grid gap-8">
        <p className="text-tiny font-bold">PROJECT MEMBER</p>
        <ul className="grid gap-8">
          {project.users.map((member) => (
            <li key={member.id}>
              <TeamMemberItem user={member} />
            </li>
          ))}
        </ul>
      </div>

      {isPc && (
        <div className="divide-y divide-primary px-12 border-l">
          {mainDatas.map(({ label, value }) => (
            <dl
              key={label}
              className="flex justify-between items-start text-xs py-12 gap-x-4"
            >
              <dt className="text-secondary flex-shrink-0">{label}</dt>
              <dd className="text-right">{value}</dd>
            </dl>
          ))}
        </div>
      )}

      {project.magazine_slug && (
        <div className="grid gap-8">
          <p className="text-tiny">LINK</p>
          <Link
            href={`https://mag.tecture.jp/project/${project.magazine_slug}`}
            target="_blank"
            className="flex items-center gap-8 border border-primary p-12 text-xs hover:bg-gray-100"
          >
            <IconBook width={16} height={16} />
            <span>TECTURE MAG</span>
          </Link>
        </div>
      )}
    </div>
  );
};
export default LeftInfoOnProjectDetail;

interface TeamMemberProps {
  user: UserResponse;
}

const TeamMemberItem: FC<TeamMemberProps> = ({ user }) => {
  return (
    <article className="flex items-start gap-8">
      <Link className="flex-shrink-0" href={`/users/${user.id}/projects`}>
        <Avatar
          size={AvatarSize.XTiny}
          src={user.icon?.urls.small || "/imgs/default_user_icon.png"}
          alt={user.screen_name}
        />
      </Link>

      <p className="text-xs">
        <Link href={`/users/${user.id}/projects`}>{user.screen_name}</Link>
      </p>
    </article>
  );
};
