import useDevice from "@/hooks/useDevice";
import { FC, PropsWithChildren } from "react";
import FlexibleGrid from "@/components/FlexibleGrid";
import ModalEntityListWrapper from "@/components/ModalEntityListWrapper";
import { ProjectResponse } from "@/web-client";

interface Props {
  projects: ProjectResponse[];
}

const ProjectItemList: FC<PropsWithChildren<Props>> = ({
  projects,
  children,
}): JSX.Element => {
  const { isSp } = useDevice();

  return (
    <ModalEntityListWrapper responses={projects}>
      <FlexibleGrid rowGap={24} columnGap={24} minWidth={isSp ? 240 : 320}>
        {children}
      </FlexibleGrid>
    </ModalEntityListWrapper>
  );
};

export default ProjectItemList;
