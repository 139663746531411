import React, { createContext, useContext, useState, ReactNode } from "react";

interface CurrentSectionContextProps {
  currentSectionId: string | null;
}

const CurrentSectionContext = createContext<
  CurrentSectionContextProps | undefined
>(undefined);

export const useCurrentSection = (): CurrentSectionContextProps => {
  const context = useContext(CurrentSectionContext);

  if (!context) {
    throw new Error(
      "useCurrentSection must be used within a CurrentSectionProvider",
    );
  }

  return context;
};

interface CurrentSectionProviderProps {
  children: ReactNode;
  currentSectionId: string | null;
}

export const CurrentSectionProvider: React.FC<CurrentSectionProviderProps> = ({
  children,
  currentSectionId,
}) => {
  return (
    <CurrentSectionContext.Provider value={{ currentSectionId }}>
      {children}
    </CurrentSectionContext.Provider>
  );
};
