import { useState, useEffect, RefObject } from "react";

interface ScrollValue {
  x: number;
  y: number;
}

export const useScrollValue = (ref?: RefObject<HTMLElement>): ScrollValue => {
  const [scrollValue, setScrollValue] = useState<ScrollValue>({ x: 0, y: 0 });

  useEffect(() => {
    const handleScroll = () => {
      if (ref?.current) {
        setScrollValue({
          x: ref.current.scrollLeft,
          y: ref.current.scrollTop,
        });
      } else {
        setScrollValue({
          x: window.scrollX,
          y: window.scrollY,
        });
      }
    };

    const target = ref?.current || window;
    target.addEventListener("scroll", handleScroll);

    // 初期スクロール位置を設定
    handleScroll();

    return () => {
      target.removeEventListener("scroll", handleScroll);
    };
  }, [ref]);

  return scrollValue;
};
