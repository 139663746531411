import IconButton from "@/components/IconButton";
import useAddList from "@/features/list/hooks/useAddList";
import { ListItemKind, ProjectDetailResponse } from "@/web-client";
import { FC } from "react";
import IconBookmark from "@/assets/imgs/svg/icon-bookmark.svg";
import { IconButtonVariants } from "@/components/IconButton/types/IconButtonVariants";
import clsx from "clsx";
import { useSignInRequiredAction } from "@/features/user_authentication";
import { getReasonText } from "@/utils/getReasonText";
import { RedirectReason } from "@/domain/values/RedirectReason";

interface AddProjectToListButtonProps {
  project: ProjectDetailResponse;
  variant?: IconButtonVariants;
}

const AddProjectToListButton: FC<AddProjectToListButtonProps> = ({
  project,
  variant = "primary",
}) => {
  const {
    modal: ModalAddList,
    isOpen,
    open,
  } = useAddList(ListItemKind.PROJECT, project);

  const { executeAction, SignInModalComponent } = useSignInRequiredAction({
    action: open,
    message: getReasonText(RedirectReason.Like),
  });

  return (
    <>
      <IconButton
        className={clsx(variant === "secondary" && "border border-primary")}
        onClick={executeAction}
        size="large"
        variant={variant}
        rounded
      >
        <IconBookmark width={16} height={16} />
      </IconButton>

      {isOpen && ModalAddList}

      {SignInModalComponent}
    </>
  );
};
export default AddProjectToListButton;
