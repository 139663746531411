import {
  DefaultApiProjectsIdPostsGetRequest,
  PostsResponse,
} from "@/web-client/api";
import client from "@/utils/api/client";
import { SWRConfiguration } from "swr";
import { AxiosResponse } from "axios";
import useFetch from "@/hooks/useFetch";

export const getPostsByProjectId = (
  request: DefaultApiProjectsIdPostsGetRequest,
): Promise<AxiosResponse<PostsResponse>> => client.projectsIdPostsGet(request);

export const useGetPostsByProjectId = (
  request: DefaultApiProjectsIdPostsGetRequest,
  config: SWRConfiguration = undefined,
) => {
  const response = useFetch(
    `/projects/${request.id}/posts`,
    async () => {
      const { data } = await getPostsByProjectId(request);

      return data.results || [];
    },
    undefined,
    {
      waitForAuth: true,
      ...config,
    },
  );

  return response;
};
