import usePagination from "@/hooks/usePagination";
import client from "@/utils/api/client";
import {
  DefaultApiTeamsIdProjectsGetRequest,
  ProjectResponse,
  ProjectsResponse,
} from "@/web-client";
import { AxiosResponse } from "axios";
import { SWRConfiguration } from "swr";

export const getTeamProjects = (
  request: DefaultApiTeamsIdProjectsGetRequest,
): Promise<AxiosResponse<ProjectsResponse>> =>
  client.teamsIdProjectsGet(request);

export const useGetTeamProjects = (
  request: DefaultApiTeamsIdProjectsGetRequest,
  config: SWRConfiguration = {},
) => {
  const { id, limit } = request;
  const response = usePagination<ProjectResponse>(
    `/teams/${id}/projects`,
    limit,
    async ({ limit, page }) => {
      const { data } = await getTeamProjects({
        ...request,
        limit,
        page,
      });

      return data;
    },
    { waitForAuth: true },
    config,
  );

  return response;
};
