import React, { createContext, useContext, useState, ReactNode } from "react";

interface CurrentPostContextProps {
  currentPostIndex: number | null;
  setCurrentPostIndex: (index: number | null) => void;
}

const CurrentPostContext = createContext<CurrentPostContextProps | undefined>(
  undefined,
);

export const useCurrentPost = (): CurrentPostContextProps => {
  const context = useContext(CurrentPostContext);

  if (!context) {
    throw new Error("useCurrentPost must be used within a CurrentPostProvider");
  }

  return context;
};

interface CurrentPostProviderProps {
  children: ReactNode;
}

export const CurrentPostProvider: React.FC<CurrentPostProviderProps> = ({
  children,
}) => {
  const [currentPostIndex, setCurrentPostIndex] = useState<number | null>(null);

  return (
    <CurrentPostContext.Provider
      value={{ currentPostIndex, setCurrentPostIndex }}
    >
      {children}
    </CurrentPostContext.Provider>
  );
};
