import React, { useEffect, useRef, FC, PropsWithChildren } from "react";

export type IntersectionDetectorChangeHandler = (
  isIntersecting: boolean,
) => void;

interface Props extends PropsWithChildren {
  onChange: IntersectionDetectorChangeHandler;
  option?: IntersectionObserverInit;
}

const IntersectionDetector: FC<Props> = ({
  children,
  onChange,
  option = {
    root: null, // ビューポートを基準にする
    rootMargin: "-50% 0px -50%", // 上下のマージンを50%に設定して、画面の中心を基準にする
    threshold: 0, // 交差が発生したらコールバックを呼び出す
  },
}) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observerCallback: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        const isIntersecting = entry.isIntersecting;
        onChange(isIntersecting);
      });
    };

    const observer = new IntersectionObserver(observerCallback, option);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [onChange]);

  return <div ref={ref}>{children}</div>;
};

export default IntersectionDetector;
