import Avatar, { AvatarSize } from "@/components/Avatar";
import Button from "@/components/Button";
import ChildrenWithSeparate from "@/components/ChildrenWithSeparate";
import { useGetTeamProjects } from "@/features/project/api/getTeamProjects";
import ProjectItem from "@/features/project/components/ProjectItem";
import ProjectItemList from "@/features/project/components/ProjectItemList";
import useDevice from "@/hooks/useDevice";
import { ProjectDetailResponse } from "@/web-client";
import Link from "next/link";
import { FC } from "react";
import IconArrowDown from "@/assets/imgs/svg/icon-arrow-down.svg";

interface Props {
  project: ProjectDetailResponse;
}
const SectionOtherProjectsOnProjectDetail: FC<Props> = ({ project }) => {
  const { isSp } = useDevice();

  const { data: projects } = useGetTeamProjects({
    id: project.team.id,
    limit: 8,
    page: 1,
    excludeProjectId: project.id,
  });

  if (!projects || projects.length === 0) return null;

  return (
    <section className="grid gap-24">
      <div className="grid gap-16">
        <div className="grid gap-8">
          <ChildrenWithSeparate>
            <Link href={`/teams/${project.team.id}/projects`}>
              <Avatar
                size={isSp ? AvatarSize.Normal : AvatarSize.Large}
                src={
                  project.team.logo
                    ? project.team.logo.urls.small
                    : "/imgs/default_team_icon.png"
                }
                alt={project.team.name}
              />
            </Link>
          </ChildrenWithSeparate>

          <p className="text-center text-xs">{project.team.name}</p>
        </div>

        <ProjectItemList projects={projects}>
          {projects.map((project) => (
            <li key={project.id}>
              <ProjectItem project={project} hasTeam={false} />
            </li>
          ))}
        </ProjectItemList>
      </div>

      <Button
        color="ghost"
        leftIcon={<IconArrowDown width={16} height={16} />}
        asChild
      >
        <Link href={`/teams/${project.team.id}/projects`}>もっと見る</Link>
      </Button>
    </section>
  );
};
export default SectionOtherProjectsOnProjectDetail;
