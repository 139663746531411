import { useMemo } from "react";
import {
  PostPinKind,
  PostResponse,
  ProductVariationWithProductResponse,
} from "@/web-client";

export const useExtractProductVariationsFromPosts = (
  posts: PostResponse[],
): ProductVariationWithProductResponse[] => {
  const productVariations = useMemo<
    ProductVariationWithProductResponse[]
  >(() => {
    if (!posts) return [];

    const allProductVariations = posts.flatMap((post) =>
      post.pins
        .filter((pin) => pin.kind === PostPinKind.PRODUCT_VARIATION)
        .map((pin) => pin.product_variation),
    );

    // idをもとに重複を削除
    return Array.from(
      new Map(allProductVariations.map((item) => [item.id, item])).values(),
    );
  }, [posts]);

  return productVariations;
};
