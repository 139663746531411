import { FC } from "react";
import { ProjectDetailResponse } from "@/web-client";
import { useGetProjects } from "@/features/project/api/getProjects";
import ProjectItem from "@/features/project/components/ProjectItem";
import ProjectItemList from "@/features/project/components/ProjectItemList";
import Button from "@/components/Button";
import IconArrowDown from "@/assets/imgs/svg/icon-arrow-down.svg";
import Link from "next/link";

interface Props {
  project: ProjectDetailResponse;
}

const SectionSimilarProjectsOnProjectDetail: FC<Props> = ({ project }) => {
  const { data: projects } = useGetProjects(
    `/building_types/${project.building_type.id}/projects`,
    {
      limit: 8,
      buildingTypeId: project.building_type.id,
      excludeProjectId: project.id,
      sort: "popular",
    },
  );

  if (projects.length === 0) return null;

  return (
    <section className="grid gap-24">
      <div className="grid gap-16">
        <h2 className="font-bold text-base">{`他の「${project.building_type.name}」のプロジェクト`}</h2>
        <ProjectItemList projects={projects}>
          {projects.map((project) => (
            <li key={project.id}>
              <ProjectItem project={project} hasTeam={false} />
            </li>
          ))}
        </ProjectItemList>
      </div>

      <Button
        color="ghost"
        leftIcon={<IconArrowDown width={16} height={16} />}
        asChild
      >
        <Link
          href={`/architectures/projects/search?building_type_id=${project.building_type.id}`}
        >
          もっと見る
        </Link>
      </Button>
    </section>
  );
};
export default SectionSimilarProjectsOnProjectDetail;
