import { ReactNode, useMemo } from "react";
import RenderIfAuth from "@/components/RenderIfAuth";
import useDisclosure from "@/hooks/useDisclosure";
import { ListItemKind } from "@/web-client";
import dynamic from "next/dynamic";
import { ListItem } from "@/consts/ListItemType";

const DynamicModalAddList = dynamic(
  () => import("@/features/list/components/ModalAddList"),
);

interface AddList {
  modal: ReactNode;
  open: VoidFunction;
  isOpen: boolean;
}

const useAddList = (kind: ListItemKind, item: ListItem): AddList => {
  const { isOpen, openHandler, closeHandler } = useDisclosure();

  const modal = useMemo(() => {
    return (
      <RenderIfAuth>
        <DynamicModalAddList
          isOpen={isOpen}
          closeHandler={() => closeHandler()}
          selectedItem={item}
          kind={kind}
        />
      </RenderIfAuth>
    );
  }, [closeHandler, isOpen, item, kind]);

  return {
    isOpen,
    modal,
    open: openHandler,
  };
};

export default useAddList;
