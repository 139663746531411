import { MutableRefObject, useCallback, useEffect, useRef } from "react";

interface SectionObserver {
  setRef: (key: string, element: HTMLElement) => void;
  currentHTMLElement: HTMLElement | null;
}

export const useSectionObserver = (
  scroller: MutableRefObject<HTMLElement> = null,
): SectionObserver => {
  // 複数のHTMLを保存するためのref
  const refs = useRef<{ [key: string]: HTMLElement | null }>({});

  // Y座標でソートされたref
  const sortedRefs = useRef<(HTMLElement | null)[]>([]);

  // 今どこにいるかを示すインデックス
  const currentIndexRef = useRef<number>(0);

  // スクロールする方向を判定するための変数
  const prevScrollTop = useRef<number>(-1);

  // 縦方向のスクロール量を取得
  const getScrollY = useCallback(() => {
    if (scroller?.current) {
      return scroller.current.scrollTop;
    }

    return window.scrollY;
  }, [scroller]);

  // 画面の中央の位置を取得
  const getCenterPosition = useCallback(
    () => window.innerHeight / 2 + getScrollY(),
    [getScrollY],
  );

  const checkDown = useCallback(() => {
    // すでに下端にいる場合は何もしない
    const nextIndex = currentIndexRef.current + 1;

    if (nextIndex >= sortedRefs.current.length) return;

    const target = sortedRefs.current[nextIndex];

    // 今のセクションの一つ後のセクションが、画面中央より上にあれば、次のセクションを再帰的に検証し、
    // 画面中央より下にあるセクションを見つける
    const center = getCenterPosition();
    const over = center > target.offsetTop;

    if (over) {
      currentIndexRef.current = nextIndex;

      checkDown();
    }

    return;
  }, [currentIndexRef, sortedRefs, getCenterPosition]);

  const checkUp = useCallback(() => {
    const nextIndex = currentIndexRef.current;

    if (nextIndex <= 0) return;

    const target = sortedRefs.current[nextIndex];

    // // 今のセクションの一つ前のセクションが、画面中央より下にあれば、次のセクションを再帰的に検証し、
    // // 画面中央より上にあるセクションを見つける
    const center = getCenterPosition();
    const over = center < target.offsetTop;

    if (over) {
      currentIndexRef.current = nextIndex - 1;

      checkUp();
    }
  }, [currentIndexRef, sortedRefs, getCenterPosition]);

  // スクロールするたびに実行される処理
  const handleScroll = useCallback(() => {
    // スクロール量を比較し、スクロールする方向を判定
    const currentScrollY = getScrollY();
    const down = currentScrollY >= prevScrollTop.current;

    // 対象をチェック
    down ? checkDown() : checkUp();

    // スクロール量を保存
    prevScrollTop.current = currentScrollY;
  }, [prevScrollTop, getScrollY, checkDown, checkUp]);

  const setRef = useCallback(
    (key: string, element: HTMLElement) => {
      // 保存
      refs.current[key] = element;

      // 画面のy座標でソート
      const sortedKeys = Object.keys(refs.current).sort((a, b) => {
        const aElement = refs.current[a];
        const bElement = refs.current[b];

        if (!aElement || !bElement) return 0;

        const aTop = aElement.getBoundingClientRect().top + getScrollY();
        const bTop = bElement.getBoundingClientRect().top + getScrollY();

        return aTop - bTop;
      });

      // Y座標でソートされたキーを元に、refを配列にして保存
      const sotedRefs = sortedKeys.map((key) => refs.current[key]);
      sortedRefs.current = sotedRefs;
    },
    [refs, sortedRefs, getScrollY],
  );

  useEffect(() => {
    const target = scroller?.current || window;
    target.addEventListener("scroll", handleScroll);

    return () => {
      target.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return {
    setRef,
    currentHTMLElement: sortedRefs.current[currentIndexRef.current],
  };
};
