import { GMAP_API_KEY } from "@/config/env";
import { LocationValue } from "@/web-client";
import { FC, useMemo } from "react";

interface Props {
  location: LocationValue;
}

const SectionMapOnProjectDetail: FC<Props> = ({ location }) => {
  const googleMapUrl = useMemo<string>(
    () =>
      `https://www.google.com/maps/embed/v1/place?key=${GMAP_API_KEY}&q=${location.lat},${location.lng}`,
    [location],
  );

  return (
    <section className="grid gap-16">
      <h2 className="font-bold text-base">物件所在地</h2>

      <iframe
        style={{ border: 0 }}
        referrerPolicy="no-referrer-when-downgrade"
        src={googleMapUrl}
        className="w-full h-[320px]"
        allowFullScreen
      />
    </section>
  );
};
export default SectionMapOnProjectDetail;
