import { AwardResultResponse } from "@/web-client";
import Image from "next/image";
import { FC, useState, useRef } from "react";
import {
  useFloating,
  arrow,
  offset,
  shift,
  useHover,
  useInteractions,
  FloatingArrow,
  useDismiss,
} from "@floating-ui/react";
import useDevice from "@/hooks/useDevice";
import clsx from "clsx";

interface Props {
  awardSlug: string;
  awardResults: AwardResultResponse[];
}

export const AwardBadge: FC<Props> = ({ awardSlug, awardResults }) => {
  const { isPc } = useDevice();
  const [isHovering, setIsHovering] = useState(false);
  const arrowRef = useRef(null);

  // Floating UIの設定
  const { refs, floatingStyles, context } = useFloating({
    open: isHovering,
    onOpenChange: setIsHovering,
    placement: "bottom-start",
    middleware: [offset(8), shift(), arrow({ element: arrowRef })],
  });

  // ホバーインタラクションの設定
  const hover = useHover(context);

  // スマホの場合のみ、ツールチップ外タップで閉じる機能を有効にする
  const dismiss = useDismiss(context, {
    enabled: !isPc,
  });

  // インタラクションの統合
  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    dismiss,
  ]);

  return (
    <div className="relative">
      <div ref={refs.setReference} {...getReferenceProps()}>
        <Image
          src={`/imgs/awards/${awardSlug}/badge.png`}
          width={30}
          height={36}
          alt={"アワードバッジ"}
        />
      </div>

      {isHovering && (
        <div
          ref={refs.setFloating}
          style={floatingStyles}
          className={clsx(
            "bg-black py-4 px-8 rounded-xxs shadow-md z-50 text-sm whitespace-nowrap text-white",
            isPc ? "text-left" : "text-right",
          )}
          {...getFloatingProps()}
        >
          <ul className="grid gap-4">
            {awardResults.map(({ prize_name }) => (
              <li key={prize_name}>{prize_name}</li>
            ))}
          </ul>
          <FloatingArrow
            ref={arrowRef}
            context={context}
            fill="black"
            width={8}
            height={5}
          />
        </div>
      )}
    </div>
  );
};
