import { FC } from "react";
import { motion } from "framer-motion";
import { ProjectDetailResponse } from "@/web-client";
import AddProjectToListButton from "@/features/project/components/AddProjectToListIconButton";
import ShareProjectIconButton from "@/features/project/components/ShareProjectIconButton";
import LikeProjectIconButtonWithBadge from "@/features/project/components/LikeProjectIconButtonWithBadge";

interface Props {
  project: ProjectDetailResponse;
}

const ProjectDetailActionButtons: FC<Props> = ({ project }) => {
  const buttonVariants = {
    hidden: { x: "100%", opacity: 0 },
    visible: (i: number) => ({
      x: 0,
      opacity: 1,
      transition: {
        delay: i * 0.12,
        type: "spring",
        stiffness: 300,
        damping: 20,
      },
    }),
  };

  return (
    <div className="grid grid-cols-3 gap-16">
      <motion.div
        custom={0}
        initial="hidden"
        animate="visible"
        variants={buttonVariants}
      >
        <LikeProjectIconButtonWithBadge project={project} />
      </motion.div>

      <motion.div
        custom={1}
        initial="hidden"
        animate="visible"
        variants={buttonVariants}
      >
        <AddProjectToListButton project={project} />
      </motion.div>

      <motion.div
        custom={2}
        initial="hidden"
        animate="visible"
        variants={buttonVariants}
      >
        <ShareProjectIconButton project={project} />
      </motion.div>
    </div>
  );
};

export default ProjectDetailActionButtons;
