import {
  CSSProperties,
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { ProjectDetailResponse } from "@/web-client";
import { useGetPostsByProjectId } from "@/features/post/api/getPostsByProjectId";
import { useExtractProductVariationsFromPosts } from "@/features/product_variation/hooks/useExtractProductVariationFromPosts";
import clsx from "clsx";
import { useCurrentSection } from "@/features/project/providers/CurrentSectionProvider";
import { useContainerRefContext } from "@/features/project/providers/ContainerRefProvider";
import { useRouter } from "next/router";

interface NavigationProps {
  project: ProjectDetailResponse;
  scrollOffset: number;
}

const NavigationOnProjectDetail: FC<NavigationProps> = ({
  project,
  scrollOffset,
}) => {
  const router = useRouter();
  const { data: posts } = useGetPostsByProjectId({
    id: project.id,
    limit: 100,
    token: router.query.token as string,
  });
  const productVariations = useExtractProductVariationsFromPosts(posts || []);

  const ref = useRef<HTMLDivElement>(null);

  const [underlineStyle, setUnderlineStyle] = useState<CSSProperties>({
    left: 0,
    width: 0,
  });

  const { currentSectionId } = useCurrentSection();

  // 各セクションのrefを管理するオブジェクト
  const sectionRefs = useRef<{ [key: string]: HTMLAnchorElement | null }>({});

  useEffect(() => {
    if (!ref.current || !currentSectionId) return;

    // currentSectionIdと一致するrefを取得する
    const target = sectionRefs.current[currentSectionId];

    if (!target) return;

    // 選択中のナビゲーションに下線を追加する
    const left = target.offsetLeft;
    const width = target.offsetWidth;
    setUnderlineStyle({ width, translate: `${left}px` });
  }, [currentSectionId]);

  // メニューの項目
  const items = useMemo(() => {
    const list = [
      {
        label: "写真",
        anchor: "photos",
      },
    ];

    if (project.attachments?.length > 0) {
      list.push({
        label: "補足資料",
        anchor: "attachments",
      });
    }

    list.push({
      label: "プロジェクト概要",
      anchor: "overview",
    });

    if (productVariations.length > 0) {
      list.push({
        label: "使用建材・家具",
        anchor: "products",
      });
    }

    return list;
  }, [project, productVariations]);

  return (
    <div className="relative">
      <nav className="flex" ref={ref}>
        {items.map((value) => (
          <NavigationItem
            key={value.anchor}
            {...value}
            current={value.anchor === currentSectionId}
            scrollOffset={scrollOffset}
            ref={(el) => {
              sectionRefs.current[value.anchor] = el;
            }}
          />
        ))}
      </nav>

      {/* 選択しているナビゲーションに下線が追従するようにする */}
      <div
        className={clsx(
          "absolute left-0 bottom-0 h-[2px] bg-black",
          "transition-all ease-in-out duration-200",
        )}
        style={underlineStyle}
      />
    </div>
  );
};
export default NavigationOnProjectDetail;

interface NavigationItemProps {
  label: string;
  anchor: string;
  scrollOffset?: number;
  current?: boolean;
}

const NavigationItem = forwardRef<HTMLAnchorElement, NavigationItemProps>(
  function NavigationItem(
    { label, anchor, current = false, scrollOffset = 0 },
    ref,
  ) {
    const { containerRef } = useContainerRefContext();

    const handleScroll = useCallback(
      (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
        targetId: string,
      ) => {
        e.preventDefault();
        const targetElement = document.getElementById(targetId);

        if (targetElement) {
          const scroller = containerRef ? containerRef.current : window;

          const padding = 24;
          const top =
            targetId === "photos"
              ? 0
              : targetElement.offsetTop - scrollOffset - padding;

          scroller.scrollTo({
            top,
            behavior: "smooth",
          });
        }
      },
      [containerRef, scrollOffset],
    );

    return (
      <a
        className={clsx(
          "py-24 px-16",
          current ? " text-primary font-bold" : "text-secondary",
          "hover:text-primary",
        )}
        key={anchor}
        href={`#${anchor}`}
        data-anchor={anchor}
        onClick={(e) => handleScroll(e, anchor)}
        ref={ref}
      >
        {label}
      </a>
    );
  },
);
